@import '_variables.scss';
@import '../../node_modules/bootstrap/scss/bootstrap';

body {
    background: $dark-blue;
}

.bm-auth {
    background: rgba(255,255,255,.07);
    border-radius: 20px;
}

ul.list {
    list-style: none;
    padding: 0;
    li {
        background: #f8f8f8;
        margin: .75rem 0;
        padding: 1rem;
        border: 1px solid rgba(0,0,0,.1);
        word-break: break-all;
        border-radius: 4px;
    }
}